<script lang="ts" setup>
// Komponent odpowiedzialny za wyświetlanie komunikatu z admin panelu np. o przerwie technicznej.

import { useLocalStorage } from '@vueuse/core';

import { fetchCurrentMessageService } from '~coreServices/admin-panel.service';

import { base64DecodeUnicode } from '~coreUtils/base64';

const currentMessage = ref('');

// zapisany w pamięci przeglądarki komunikat w base64
const currentMessageInMemory = useLocalStorage<string>('current-message', '');

// czy pokazać komunikat z admin panelu - jeśli user zamknie to już nie pokaże się
const showCurrentMessage = computed(
  () => currentMessage.value && currentMessageInMemory.value !== currentMessage.value
);

onMounted(async () => {
  const response = await fetchCurrentMessageService();

  if (response) {
    currentMessage.value = response;
  }
});

// zapisuje treść komunikatu base64 w pamięci przeglądarki
function closeAndSave() {
  currentMessageInMemory.value = currentMessage.value;
}
</script>

<template>
  <CdsAlert
    v-if="showCurrentMessage"
    variant="solid"
    type="warning"
    size="small"
    icon="info_circle"
    :message="base64DecodeUnicode(currentMessage)"
    :inline-actions="true"
    primary-action-text="Zamknij"
    @click:primary-action="closeAndSave"
  />
</template>
